.Dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  width: 136px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #D6D6D6;
  background: #FFF;
  padding-left: 12px;
  padding-right: 10px;
  cursor: pointer;

  color: #333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  &:hover {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  }

  &.open {
    border: 1px solid #000;
  }

  .options {
    position: absolute;
    z-index: 1;
    top: 40px;
    left: 0;
    width: 100%;
    border-radius: 2px;
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 5px 0;

    .option {
      display: flex;
      align-items: center;
      height: 28px;
      padding-left: 12px;

      &.selected {
        background: #E5E3FF;
      }

      &:hover {
        background: #EFEFEF;
      }
    }
  }
}
