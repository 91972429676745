.login {
  width: 100%;
  height: 100vh;
  background: #6935EC;

  .polytl1 {
    position: absolute;
    left: 200px;
    top: 50px;
  }

  .polytl2 {
    position: absolute;
    top: 149px;
    left: 17.8%;
  }

  .polybr1 {
    position: absolute;
    bottom: 276px;
    right: 27.2%;
  }

  .polybr2 {
    position: absolute;
    bottom: 487px;
    right: 7.4%;
  }

  .polybr3 {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .polybr4 {
    position: absolute;
    bottom: 60px;
    right: 15.1%;
  }

  .polytr1 {
    position: absolute;
    top: 163px;
    right: 33.1%;
  }

  .polytr2 {
    position: absolute;
    top: 50px;
    right: 24.5%;
  }

  .polybl1 {
    position: absolute;
    bottom: 293px;
    left: 26.8%;
  }

  .polybl2 {
    position: absolute;
    bottom: 431px;
    left: 4.5%;
  }

  .polybl3 {
    position: absolute;
    bottom: 345px;
    left: 20.8%;
  }

  .polybl4 {
    position: absolute;
    bottom: 100px;
    left: 4.5%;
  }

  .frame {
    background: white;
    width: 375px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 25px 48px;
    border-radius: 6px;

    .set-password-title {
      font-size: 36px;
      font-weight: 700;
      margin-top: 43px;
      margin-bottom: 37px;
    }

    .forgot-prompt {
      width: 307px;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    .title {
      display: flex;
      gap: 16px;
      align-items: center;
      color: #000;
      font-size: 36px;
      font-weight: 700;

      padding-bottom: 18px;
    }

    .text {
      width: 257px;
      color: #777777;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Nunito Sans', sans-serif;
      text-align: center;
    }

    .email-title {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      align-self: flex-start;
      margin-top: 24px;
    }

    .email {
      width: 100%;
      height: 50px;
      margin-top: 8px;
      border-radius: 3px;
      border: 1px solid #DDD;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .password-title {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      align-self: flex-start;
       margin-top: 18px;
    }
    .password {
      width: 100%;
      height: 50px;
      margin-top: 8px;
      border-radius: 3px;
      border: 1px solid #DDD;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .forgot-password {
      cursor: pointer;
      color: #6935EC;
      font-size: 14px;
      font-weight: 600;
      margin-top: 7px;
      align-self: flex-end;
    }

    .log-in {
      cursor: pointer;
      width: 280px;
      height: 48px;
      border-radius: 6px;
      background: #5338F5;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 40px;
    }

    .error {
      width: 330px;
      color: #EA2424;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      margin-top: 10px;
    }

    .password-error {
      align-self: flex-end;
      width: unset;
    }

    .or {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      color: #4E4E4E;
      font-size: 12px;
      font-weight: 900;
      text-transform: uppercase;
      margin-top: 21px;

      .hr {
        height: 1px;
        flex-grow: 1;
        background: #D1D1D1;
      }
    }


    .button {
      margin-top: 23px;
      padding-bottom: 16px;
    }

  }

  .loading {
    width: 100vw;
    height: 100vh;
    background: #70F4CC;
    opacity: 80%;
    display: flex;
    position: absolute;
    justify-content: center;

    .animation {
      width: 268px;
    }
  }
}