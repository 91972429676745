.organization {
  font-family: 'Nunito Sans', sans-serif;
  .home {
    cursor: pointer;
    color: #000;
    font-size: 14px;
    margin: 33px 0 0 100px;
  }

  .filters {
    display: flex;
    height: 109px;
    gap: 25px;

    .filter {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .sort-filter .Dropdown {
      width: 166px;
    }
  }

  .canvas {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 45px 100px 0;

    .emptyState {
      font-weight: 800;
      color: black;
      text-align: center;
      transform: translateY(-115px) translateY(-50%) translateY(50vh);

      .contact {
        color: #1AFFBA;
        text-decoration: underline;
      }
    }

    .project {
      width: 100%;

      .org {
        display: flex;
        justify-content: space-between;
        gap: 34px;

        .manage-users {
          cursor: pointer;
          flex-grow: 1;

          display: flex;
          gap: 5px;
          justify-content: flex-end;
          color: #333333;

          svg {
            opacity: 25%;
          }

          &:hover {
            color: #000000;
            text-decoration: underline;
            svg {
              opacity: unset;
            }
          }
        }

        .add-content {
          cursor: pointer;

          display: flex;
          gap: 5px;
          color: #333333;
          svg {
            opacity: 25%;
          }

          &:hover {
            color: #000000;
            text-decoration: underline;
            svg {
              opacity: unset;
            }
          }

        }

        .name {
          color: black;
          font-weight: 700;
          font-size: 30px;
          margin-bottom: 41px;
        }
      }

      .group-name {
        color: #000;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 35px;
      }

      .cards {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 39px;
        margin-bottom: 109px;
        margin-left: 60px;
      }
    }
  }
}