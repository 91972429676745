.asset-dialog {
  position: absolute;
  top: 0;
  left: 0;
  background: #000000A6;
  width: 100vw;
  height: 100vh;

  .dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 590px;
    flex-shrink: 0;
    background: white;
    position: absolute;
    left: 50vw;
    top: 132px;
    transform: translateX(-50%);
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    padding: 36px 30px;


    .upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 40px;
      margin-top: 10px;
      border-radius: 2px;
      border: 1px solid #D6D6D6;
      color: #5338F5;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: #3922C0;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      }
    }

    .separator {
      display: flex;
      margin-top: 37px;
      width: 100%;
      align-items: center;
      gap: 10px;
      color: #333;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;

      .line {
        background: #999;
        height: 1px;
        flex-grow: 1;
      }
    }

    .url-title {
      margin-top: 40px;
      width: 100%;
      color: #333;
      font-weight: 600;
      letter-spacing: 2.8px;
      text-transform: uppercase;
    }

    input {
      width: 560px;
      height: 50px;
      margin-top: 8px;
      border-radius: 3px;
      border: 1px solid #DDD;
      padding: 0 15px;
      outline: none;
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 22px;
      gap: 12px;

      .button {
        display: flex;
        width: 92px;
        height: 40px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        border-radius: 3px;
        cursor: pointer;

        &.ok {
          background: #5338F5;
          color: #FFF;

          &:hover {
            background: #3922C0;
          }
        }

        &.cancel {
          color: #666;
        }

        &.disabled {
          opacity: 40%;
        }
      }
    }

    .uploading {
      margin-left: 145px;
      margin-top: 14px;
      color: #444;
      font-size: 20px;
      font-weight: 700;
      align-self: flex-start;
    }

    .loader {
      position: absolute;
      left: 30px;
      top: -15px;
      width: 115px;
      height: 115px;
      background: linear-gradient(135deg, #A4A0F6, #68F0BF, #F8CA45, #F5C3C7);
      background-size: 200% 200%;
      border: 1px solid #DDDDDD;
      border-radius: 3.5px;
      animation: gradient 5s ease infinite;
    }

    .progress {
      position: absolute;
      top: 102px;
      left: 30px;
      width: 115px;
      display: flex;
      align-items: flex-start;

      .bar {
        background: #5338F5;
        height: 5px;
        border-radius: 2.5px;
      }
    }

    @keyframes gradient {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }

    .type-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 30px;
      top: -15px;
      width: 115px;
      height: 115px;
      border: 1px solid #DDD;
      background: #F5C3C7;

      &.video {
        background: #A4A0F6;
      }

      &.audio {
        background: #68F0BF;
      }

      &.text {
        background: #F8CA45;
      }

      &.pdf {
        background: #FF7676;
      }

      &.image {
        background: #38D2F4;
      }

      &.zip {
        background: #DC91F6;
      }
    }

    .filename {
      width: 430px;
      margin-top: 34px;
      margin-bottom: 8px;
      color: #444;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.small {
        margin-top: 6px;
        margin-bottom: 0;
        color: #666;
        font-size: 13px;
        font-weight: 400;
        margin-left: 145px;
        align-self: flex-start;
        text-align: left;
      }
    }

    .name-title {
      width: 100%;
      margin-top: 45px;
      color: #333;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;
      text-transform: uppercase;
    }

    .file-name {
      border-radius: 3px;
      border: 1px solid #5338F5;
      background: #FAFAFA;
    }

    .access {
      margin-top: 26px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 60px;
      cursor: pointer;

      color: #333;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;
      text-transform: uppercase;

      .option {
        display: flex;
        align-items: center;
        gap: 5px;

        .radio {
          margin-right: 5px;
        }
      }
    }

    .buttons {
      align-items: center;

      .error {
        color: #EA2424;
        font-size: 14px;
        font-weight: 600;
      }

      .save {
        color: white;
        border-radius: 3px;
        background: #5338F5;

        &:hover {
          background: #3922C0;
        }
      }
    }

  }

  .file-input {
    display: none;
  }
}
