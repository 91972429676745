.root {
  .canvas {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 45px 153px 0;
    gap: 33px;

    .org {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 195px;
      height: 230px;
      border-radius: 3px;

      .name {
        width: 151px;
        color: #FFF;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding: 0 22px 0;
        margin-top: 83px;
        text-align: center;
        transform: translateY(-50%);
      }

      .count {
        padding: 8px 18px;
        margin-bottom: 39px;
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.28px;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.72);
        backdrop-filter: blur(2px);
      }
    }

    .tooltip {
      color: #FFF;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;

      .label {
        display: inline-block;
        width: 79px;
      }
    }
  }
}