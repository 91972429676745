.user-manager {
  position: fixed;
  top:0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 65%;
    top: 0;
  }

  .dialog {
    background: #FFFFFF;
    width: 590px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    flex-grow: 1;

    margin-top: 149px;
    margin-bottom: 78px;
    padding: 40px;

    display: flex;
    flex-direction: column;

    .title {
      color: #000000;
      font-size: 30px;
      font-weight: 700;
      margin-top: 4px;
    }

    .add-user {
      color: #333333;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 2.8px;
      margin-top: 23px;
      text-transform: uppercase;
    }

    .user-email {
      border-radius: 3px;
      border: 1px solid #5338F5;
      background: #FAFAFA;
      height: 50px;
      margin-top: 8px;
      padding: 0 14px;
    }

    .list {
      flex-grow: 1;
      margin-top: 23px;
      border-bottom: 1px solid #D1D1D1;

      .user {
        display: flex;
        padding: 14px 14px 14px 19px;
        align-items: center;

        .profile {
          width: 42px;
          height: 42px;
          border-radius: 21px;
        }

        .details {
          display: flex;
          flex-direction: column;
          gap: 2px;
          margin-left: 12px;
          color: #666;
          font-weight: 700;

          .email {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 29px;
      gap: 21px;

      .button {
        height: 40px;
        padding: 0 21px 0 23px;
        display: flex;
        align-items: center;
        border-radius: 3px;
        font-weight: 600;
        cursor: pointer;

        &.add {
          background: #5338F5;
          color: white;

          &.disabled {
            opacity: 50%;
          }
        }

        &.cancel {
          color: #666666;
        }
      }
    }

  }

}