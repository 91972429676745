@import "../assets/variables";

.status {
  position: relative;
  cursor: pointer;
  background: $not_started;
  border-radius: 100px;
  padding: 5px 25px;
  color: white;

  &.hover:hover {
    background: $not_started_hover;
  }

  &.in_progress {
    background: $in_progress;

    &.hover:hover {
      background: $in_progress_hover;
    }
  }

  &.delivered {
    background: $delivered;

    &.hover:hover {
      background: $delivered_hover;
    }
  }

  &.in_review {
    background: $in_review;

    &.hover:hover {
      background: $in_review_hover;
    }
  }

  .status-card {
    position: absolute;
    top: 38px;
    left: 18px;
    width: 96px;
    z-index: 1;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7px 0;

    font-family: 'Nunito Sans', sans-serif;
    color: white;
    font-size: 12px;
    gap: 6px;
    border-radius: 2px;
    box-shadow: 0 1.7580645084381104px 3.5161290168762207px 0 rgba(0, 0, 0, 0.25);

    .item {
      border-radius: 10.5px;
      height: 21px;
      width: 81px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;

      &.delivered {
        background: $delivered;
      }

      &.in_progress {
        background: $in_progress;
      }

      &.not_started {
        background: $not_started;
      }

      &.in_review {
        background: $in_review;
      }
    }
  }

}