@import "../assets/variables";

.assets {
  display: flex;
  flex-direction: column;

  .assets-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 22px;

    .include-archived {
      cursor: pointer;
      color: #666;
      font-size: 13px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 31px;
    }

    .buttons {
      display: flex;
      gap: 17px;
      flex-grow: 1;
      justify-content: flex-end;

      .button {
        display: flex;
        font-size: 16px;
        width: 140px;
        height: 44px;
        border-radius: 2px;
        border: 1px solid #D6D6D6;
        background: #FFF;
        align-items: center;
        cursor: pointer;

        svg {
          padding: 16px 17px 14px 14px;
          border-right: 1px solid #D6D6D6;
        }

        .text {
          flex-grow: 1;
          text-align: center;

          color: #333;
          font-weight: 600;
        }

        &:hover {
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
          svg .plus {
            fill: black;
          }

          .text {
            color: black;
          }
        }
      }
    }
  }

  .tiles {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 18px;
    padding-left: 4px;
    padding-bottom: 28px;
    border-bottom: 1px solid #D1D1D1;

    .download-all {
      cursor: pointer;
      width: 99px;
      color: #6935EC;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      line-height: 16px; /* 123.077% */
    }
  }

  .no-assets {
    display: flex;
    width: 427px;
    height: 125px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #DDDDDD7F;
    background: #FFFFFF7F;

    color: #666;
    font-size: 16px;
    font-weight: 600;

    justify-content: center;
    align-items: center;
  }

  .asset {
    position: relative;
    width: 115px;
    height: 125px;
    border-radius: 3px;
    border: 1px solid #DDD;
    background: #F5C3C7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    svg {
      padding-bottom: 30px;
      opacity: 70%;
    }

    .name {
      height: 29px;
      width: 100%;
      font-size: 14px;
      padding-top: 4px;
      background: #E2E2E2;
      text-align: center;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-radius: 0 0 2px 2px;
    }

    &.video {
      background: #A4A0F6;
    }

    &.audio {
      background: #68F0BF;
    }

    &.text {
      background: #F8CA45;
    }

    &.pdf {
      background: #FF7676;
    }

    &.image {
      background: #38D2F4;
    }

    &.zip {
      background: #DC91F6;
    }

    .hover-buttons {
      display: none;
      height: 20px;
      width: 100%;
      position: absolute;
      top: -26px;
      right: 0;
      font-size: 10px;
      color: #999999DD;
      gap: 10px;
      justify-content: flex-end;

      .button {
        opacity: 0.25;

        &:hover {
          opacity: 1;
        }
      }
    }

    .badge {
      position: absolute;
      top: 0;
      right: 0;
    }

    .delete-confirm {
      position: absolute;
      right: 0;
      top: -20px;
      width: 181px;
      height: 102px;
      border-radius: 2px;
      background: #FFF;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

      color: #333;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      .message {
        padding: 13px 14px 10px 15px;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 72px;
          height: 28px;
          border-radius: 2px;
          font-weight: 600;
          color: #666;

          &:hover {
            background: #EFEFEF;
          }

          &.delete {
            background: #EA2424;
            color: white;

            &:hover {
              background: #B61E1E;
            }
          }
        }
      }
    }

    .tooltip {
      background-color: #333333CC;
      z-index: 2;
      font-size: 10px;
      font-weight: 400;

      padding: 3px 5px;
      border-radius: 3px;
    }

    .hide {
      display: none;
    }

    &:hover {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);

      svg {
        opacity: 1;
      }

      .name {
        background: white;
      }

      .hover-buttons {
        display: flex;
      }
    }
  }
}