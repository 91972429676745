.images {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .title {
    font-weight: bold;
    font-size: 24px;
    margin-top: 16px;

    img {
      height: 20px;
    }
  }

  .drop {
    width: 640px;
    height: 640px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    border: 3px dashed gray;
    border-radius: 5px;

    &.valid {
      border-color: green;
    }

    &.invalid {
      border-color: red;
    }
  }

  .canvas {
    width: 640px;
    height: 640px;
    border: 3px black solid;
  }

  .hide {
    display: none;
  }

  .bg {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      height: 30px;
      width: 60px;
      text-align: center;
    }

    svg {
      cursor: pointer;
    }

    .chrome-picker {
      position: absolute;
      right: -225px;
      top: 0;
    }
  }

  .scale {
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      width: 200px;
    }
  }

  .button {
    display: flex;
    width: 92px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    background: #5338F5;
    color: #FFF;

    &:hover {
      background: #3922C0;
    }

    &.disabled {
      opacity: 40%;
      &:hover {
        background: #5338F5;
      }
    }
  }
}