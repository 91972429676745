.video-playlist2 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  video {
    height: 100vh;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) -0.02%, rgba(0, 0, 0, 0.2) 10.92%, rgba(0, 0, 0, 0) 26.55%, rgba(0, 0, 0, 0) 85.93%, rgba(0, 0, 0, 0.582473) 92.19%, rgba(0, 0, 0, 0.7) 100%);

    .button {
      pointer-events: all;
      cursor: pointer;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    .close {
      position: absolute;
      top: 18px;
      right: 18px;
    }

    .volume {
      position: absolute;
      bottom: 27px;
      left: 18px;
    }

    .bottom {
      position: absolute;
      cursor: pointer;
      bottom: 22px;
      right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;

      .share {
        margin-right: 6px;
      }

      .like {
        margin-right: 6px;
        margin-bottom: 10px;
      }

      .comments {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:8px;
        pointer-events: all;
        cursor: pointer;

        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: -0.01em;

        color: black;
        background: white;
        border-radius: 100px;
        padding: 8px 22px;
      }
    }
  }

  .play-pause {
    display: flex;
    position: absolute;
    top:50%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%,-50%);
    pointer-events: none;
  }

  .conversation {
    position: absolute;
    width: 100vw;
    height: 50vh;
    top: 100vh;
    background: white;
    //transition: 400ms;
    overflow-y: scroll;

    &.shown {
      top: 50vh;
    }

    .header {
      position: relative;
      svg {
        z-index: 1;
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
}