.add-content-dialog {
  position: fixed;
  top:0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 65%;
    top: 0;
  }

  .dialog {
    background: #FFFFFF;
    width: 650px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;

    padding: 50px 30px 36px;

    display: flex;
    flex-direction: column;

    .group {
      display: flex;
      align-items: center;
      gap: 13px;
      padding-bottom: 32px;

      .content-group {
        color: #333;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2.8px;
        text-transform: uppercase;
      }

      .Dropdown {
        color: #333;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
      }
    }

    .enter-url {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.8px;
      text-transform: uppercase;
    }

    .url {
      border-radius: 3px;
      border: 1px solid #DDDDDD;
      background: white;
      height: 50px;
      margin-top: 8px;
      padding: 0 14px;
    }

    .comment {
      border-radius: 3px;
      border: 1px solid #DDDDDD;
      background: #FAFAFA;
      height: 50px;
      margin-top: 30px;
      padding: 0 14px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 29px;
      gap: 21px;

      .button {
        height: 40px;
        padding: 0 21px 0 23px;
        display: flex;
        align-items: center;
        border-radius: 3px;
        font-weight: 600;
        cursor: pointer;

        &.ok {
          background: #5338F5;
          color: white;

          &.disabled {
            opacity: 50%;
          }
        }

        &.cancel {
          color: #666666;
        }
      }
    }

  }

}