.document {
  font-family: 'Nunito Sans', sans-serif;

  .canvas {
    padding: 33px 100px;

    .project {
      font-size: 20px;
      cursor: pointer;
    }

    .details-row {
      padding-top: 15px;
      display: flex;
      gap: 30px;
      margin-bottom: 43px;

      img {
        width: 120px;
        height: 120px;
        border-radius: 3px;
        object-fit: cover;
        border: 2px solid #EFEFEF;
        cursor: pointer;
      }

      .details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 21px;
        flex-grow: 1;

        .title {
          font-size: 30px;
          font-weight: 700;
          line-height: 40px;
          text-decoration: underline;
          cursor: pointer;
        }

        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 30px;

          .due {
            cursor: pointer;
            color: #666;
            font-size: 13px;
            font-weight: 700;

            &:hover {
              color: #000000;
            }
          }
          .picker {
            position: absolute;
            background: white;
            z-index: 1;
            border: black 1px solid;
            padding: 1px;
          }
        }
      }

      input {
        display: none;
      }
    }


    .comments-title {
      font-size: 20px;
      font-weight: 600;
      margin-top: 34px;
      margin-bottom: 22px;
    }

    .comments {
      display: flex;
      flex-direction: column;
      gap: 18px;

      .no-comments {
        color: #666;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 22px;
      }

      .add-comment {
        display: flex;
        flex-direction: row;
        width: 883px;
        border-radius: 3px;
        border: 1px solid #DDD;
        background: #FFF;

        img {
          margin: 9px 0 12px 12px;
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }

        input {
          flex-grow: 1;
          border: none;
          padding: 15px 20px;
          outline: none;
        }
      }
    }
  }
}