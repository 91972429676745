.emptyState {
  .message {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
    color: black;
    text-align: center;
    transform: translateY(-115px) translateY(-50%) translateY(50vh);

    .contact {
      color: #1AFFBA;
      text-decoration: underline;
    }
  }
}
