.header {
  height: 70px;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #D1D1D1;

  .logo {
    margin-left: 32px;
  }

  .logout {
    color: #6935EC;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 30px;
  }
}