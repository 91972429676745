.comment {
  display: flex;
  flex-direction: row;

  img {
    margin-left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }

  .content {
    margin-left: 11px;

    .details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #666;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;

      .date {
        font-style: italic;
      }

      .comment-menu {
        position: relative;
        opacity: 25%;
        cursor: pointer;

        .menu {
          position: absolute;
          right:0;
          top:32px;
          width: 136px;
          height: 60px;
          border-radius: 2px;
          background: #FFF;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
          line-height: 28px;
          padding-top: 5px;

          color: #333;
          .delete {
            color: #EA2424;
          }
          .menu-item {
            padding-left: 12px;
            font-weight: 400;
            &:hover {
              background: #EFEFEF;
            }
          }
        }

        .delete-confirm {
          position: absolute;
          right:0;
          top:32px;
          width: 181px;
          height: 102px;
          border-radius: 2px;
          background: #FFF;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

          color: #333;
          font-weight: 400;
          text-align: center;

          .message {
            padding: 13px 14px 10px 15px;
          }

          .buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 5px;

            .button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 72px;
              height: 28px;
              border-radius: 2px;
              font-weight: 600;
              color: #666;

              &:hover {
                background: #EFEFEF;
              }

              &.delete {
                background: #EA2424;
                color: white;

                &:hover {
                  background: #B61E1E;
                }
              }
            }
          }
        }

        .hide {
          display: none;
        }

        &.open {
          opacity: 1;
          svg .bg {
            fill: #EEE;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .text {
      border-radius: 3px;
      background: #FAFAFA;
      width: 811px;
      padding: 12px 11px;

      color: #444;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }

    .editor {
      width: 818px;
      height: 46px;
      border-radius: 3px;
      border: 1px solid #5338F5;
      background: #FAFAFA;
      outline: none;
      padding-left: 11px;
    }

    .edit-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 8px;
      margin-right: 11px;
      gap: 5px;

      .button {
        display: flex;
        width: 72px;
        height: 28px;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: #666;
        cursor: pointer;

        &:hover {
          background: #EFEFEF;
        }

        &.save {
          background: #5338F5;
          color: #FFF;

          &:hover {
            background: #3922C0;
          }
        }
      }
    }
  }
}
