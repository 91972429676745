.create {
  position: relative;
  background: #E8F791;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 34px;
  column-gap: 64px;
  justify-content: center;
  padding-bottom: 56px;
  align-items: start;
  overflow: hidden;

  .bg-lines {
    position: absolute;
  }

  .triangle {
    position: absolute;
    right: 0;
    top:700px;
    transform: rotate(-50deg);
  }

  .hero {
    position: absolute;
    right: 0;
    top: 1000px;
    border-radius: 0 0 50% 50%;
    transform: translateX(50px);
  }

  .what-youll-get {
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 225px auto;
    background: #F8F1F1;
    border: 5px solid white;
    border-radius: 10px;
    width: 1085px;
    height: 198px;
    padding: 20px 34px;
    box-sizing: border-box;
    column-gap: 32px;
    margin-top: 40px;
    justify-self: center;

    svg {
      grid-row-start: 1;
      grid-row-end: 3;
    }

    .title {
      font-family: "Red Hat Display", sans-serif;
      font-weight: 900;
      font-size: 22px;
      line-height: 32px;
      grid-column-start: 2;
      grid-row-start: 1;
      text-transform: uppercase;
    }
    .body {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      grid-column-start: 2;
      grid-row-start: 2;
    }
  }

  .lets-create {
    z-index: 1;
    background: white;
    border-radius: 10px;
    width: 718px;
    grid-column-start: 1;
    padding: 38px 68px 53px;
    box-sizing: border-box;

    .title {
      font: 600 40px "Red Hat Display",sans-serif;
      padding-bottom: 26px;
    }

    .description {
      font-size: 24px;
      line-height: 32px;
    }

    .subtitle {
      font: 700 20px "Red Hat Display",sans-serif;
      margin-top: 38px;
    }

    .Dropdown {
      width: 287px;
      height: 40px;
      margin-top: 9px;
    }

    .body {
      margin: 15px 0;
    }

    .input {
      width: 100%;
      height: 40px;
      margin-top: 6px;
    }

    .under {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 11px;
      gap: 10px;

      .error {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #DF1B41;
        flex-shrink: 0;

        &.hidden {
          opacity: 0;
        }
      }

      .note {
        text-align: end;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .checkout {
    z-index: 1;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    width: 448px;
    grid-column-start: 2;
    padding: 48px 48px 53px 47px;
    box-sizing: border-box;

    .title {
      font: 700 32px/36px "Red Hat Display", sans-serif;
      margin-bottom: 24px;
    }

    .line {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      .description {font-weight: 600;}
      margin-bottom: 4px;
    }
    .total {
      font-weight: 700;
      font-size: 30px;
    }
    .hr {
      height: 1px;
      width: 100%;
      background: black;
      opacity: 70%;
      margin: 28px 0 23px;
    }
    .delivered {
      font-weight: 600;
      font-size: 16px;
      margin-top: 42px;
    }
    .formats {
      margin-top: 8px;
    }
    .button {
      display: flex;
      width: 266px;
      height: 72px;
      background: #8163F6;
      align-self: center;
      border-radius: 6px;
      color: white;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      font-family: "Red Hat Display", sans-serif;
      font-weight: 600;
      font-size: 20px;
      cursor: pointer;
    }

    .need-more {
      font-weight: 600;
      font-size: 16px;
      margin-top: 42px;
    }
    .one-at-a-time {
      margin-top: 8px;
    }

  }

  .payment {
    z-index: 1;
    background: white;
    border-radius: 10px;
    padding: 48px 48px 53px 47px;

    .terms {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 13px;
      margin-top: 16px;
    }

    .buttons {
      margin-top: 24px;
      display: flex;
      gap: 20px;
      justify-content: space-around;
      .button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 231px;
        height: 72px;
        border-radius: 6px;
        background: #8163F6;
        font: 600 20px "Red Hat Display",sans-serif;
        color: white;

        &.cancel {
          width: 164px;
          background: white;
          border: 1px solid #8163F6;
          color: #8163F6;
        }
      }
    }
  }
}