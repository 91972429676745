$not_started: #EC795C;
$not_started_hover: #D05A3D;

$in_progress: #6935EC;
$in_progress_hover: #4926A3;

$delivered: #00ADA3;
$delivered_hover : #238F89;

$in_review: #348EE1;
$in_review_hover: #186BCC;
