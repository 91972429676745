@import "../assets/variables";

.item {
  width: 170px;
  cursor: pointer;
  position: relative;
  height: 303px;
  background: #FFFFFF;
  border-radius: 3px;

  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  .hover-buttons {
    display: none;
    height: 20px;
    width: 100%;
    position: absolute;
    top: -26px;
    right: 0;
    font-size: 10px;
    color: #999999DD;
    gap: 10px;
    justify-content: flex-end;
    padding-bottom: 6px;

    .button {
      opacity: 0.25;

      &:hover {
        opacity: 1;
      }
    }
  }

  .image {
    width: 170px;
    height: 303px;
    object-fit: cover;
    border-radius: 3px;
  }

  .gradient {
    width: 170px;
    height: 303px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.7) 100%);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
  }

  .title {
    position: absolute;
    left: 0;
    bottom: 122px;
    margin: 0 20px;

    color: #FFFFFF;
  }

  .status-container {
    position: absolute;
    bottom: 81px;
    left: 0;
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .status {
      cursor: pointer;
      background: $not_started;
      border-radius: 100px;
      padding: 0 25px;
      color: white;

      &.in_progress {
        background: $in_progress;
      }

      &.delivered {
        background: $delivered;
      }

      &.in_review {
        background: $in_review;
      }
    }
  }

  .due {
    position: absolute;
    left: 0;
    bottom: 22px;
    margin: 0 20px;

    color: #FFFFFF;
  }

  .counter {
    color: white;
    background: #d05a3d;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .delete-confirm {
    position: absolute;
    right: 0;
    top: -20px;
    width: 181px;
    height: 102px;
    border-radius: 2px;
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

    color: #333;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    .message {
      padding: 13px 14px 10px 15px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 28px;
        border-radius: 2px;
        font-weight: 600;
        color: #666;

        &:hover {
          background: #EFEFEF;
        }

        &.delete {
          background: #EA2424;
          color: white;

          &:hover {
            background: #B61E1E;
          }
        }
      }
    }
  }

  .edit-dialog {
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 65%;
      top: 0;
    }

    .dialog {
      display: flex;
      flex-direction: column;
      z-index: 2;
      width: 650px;
      height: 449px;
      background-color: white;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
      border-radius: 5px;

      .image {
        position: absolute;
        width: 115px;
        height: 154px;
        border-radius: 3px;
        border: 1px solid #DDDDDD;
        left: 30px;
        top: -15px;
        cursor: default;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
        }

        .gradient {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.70) 100%), lightgray -101.976px -6.733px / 195.783% 120.792% no-repeat, #000;
        }
      }

      .edit-title {
        color: #444;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        padding: 30px 75px 0 175px;
      }

      .url {
        color: #666;
        font-size: 13px;
        font-weight: 400;
        padding: 8px 75px 0 175px;
      }

      .story-title-header {
        color: #333;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2.8px;
        text-transform: uppercase;
        position: absolute;
        top: 175px;
        left: 30px;
      }

      .story-title {
        width: 570px;
        height: 50px;
        position: absolute;
        top: 201px;
        left: 30px;
        border-radius: 3px;
        border: 1px solid #5338F5;
        background: #FAFAFA;
        color: #444;
        font-size: 16px;
        font-weight: 600;
        padding: 1px 10px;
      }

      .story-image-header {
        color: #333;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2.8px;
        text-transform: uppercase;
        position: absolute;
        top: 265px;
        left: 30px;
      }

      .story-image {
        width: 570px;
        height: 50px;
        position: absolute;
        top: 291px;
        left: 30px;
        border-radius: 3px;
        border: 1px solid #5338F5;
        background: #FAFAFA;
        color: #444;
        font-size: 16px;
        font-weight: 600;
        padding: 1px 10px;
      }

      .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        position: absolute;
        bottom: 30px;
        right: 30px;

        .button {
          display: flex;
          width: 92px;
          height: 40px;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          border-radius: 3px;
          cursor: pointer;

          &.save {
            background: #5338F5;
            color: #FFF;

            &:hover {
              background: #3922C0;
            }
          }

          &.cancel {
            color: #666;
          }
        }
      }
    }
  }

  &:hover {
    .hover-buttons {
      display: flex;
    }
  }
}
