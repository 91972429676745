.self-tell-us {
  background: #6CC399;
  display: flex;
  justify-content: center;
  padding: 65px 0;

  .form {
    display: flex;
    flex-direction: column;
    width: 718px;
    background: white;
    border-radius: 10px;
    padding: 26px 40px 50px 47px;

    .title {
      font-family: "Red Hat Display", sans-serif;
      font-size: 48px;
      line-height: 60px;
      font-weight: 600;
    }

    .body {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .sub-header {
      font-family: "Red Hat Display", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 5px;
    }

    .description {
      resize: none;
      width: 582px;
      height: 169px;
    }

    .industry {
      display: flex;
      gap: 11px;
      margin: 10px 0;
      align-items: center;
    }

    .other {
      width: 287px;
      height: 40px;
    }

    .email {
      width: 100%;
      height: 40px;
      margin-top: 18px;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 266px;
      height: 66px;
      background: #8163F6;
      border-radius: 6px;
      font-family: "Red Hat Display", sans-serif;
      font-weight: 600;
      color: white;
      font-size: 20px;
      align-self: center;
    }

    .error {
      display: flex;
      align-items: center;
      color: #DF1B41;
      gap: 6px;
      font-weight: 500;
      font-size: 14px;

      &.hidden {
        opacity: 0;
      }
    }
  }

  &.mobile {
    .form {
      width: 100%;
      margin: 20px 8px 52px 7px;
      padding: 18px 21px 55px;

      .title {
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        margin-bottom: 8px;
      }

      .body {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 16px;
      }

      .sub-header {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 5px;
      }

      .description {
        width: 100%;
      }

    }
  }
}