.self-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  padding-left: 100px;
  padding-right: 101px;
  gap: 15px;

  .spacer {
    flex-grow: 1;
  }

  .contact {
    font-weight: 800;
    color: #8163F6;
    font-size: 16px;
    cursor: pointer;
  }

  &.mobile {
    height: 46px;
    padding-left: 20px;
    padding-right: 20px;
    .logo {
      width: 77px;
    }
    .by {
      display: none;
    }
    .contact {
      font-size: 12px;
    }
  }
}