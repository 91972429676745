.welcome {
  .button {
    width: 266px;
    height: 72px;
    background: #8163F6;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
  }

  .top {
    background: #E8F791;
    display: flex;
    padding-left: 100px;
    padding-bottom: 52px;
    flex-direction: column;

    .title {
      z-index: 1;
      font-family: "Red Hat Display", sans-serif;
      font-size: 60px;
      font-weight: 700;
      line-height: 70px;
      margin-bottom: 23px;
      margin-top: 83px;
    }
    .subtitle {
      z-index: 1;
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -1.5%;
      margin-bottom: 36px;
    }
    .form {
      z-index: 1;
      background: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 56px 44px 46px;
      text-align: center;
      width: 550px;
      box-sizing: border-box;
      gap: 8px;
      margin-bottom: 46px;

      .form-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -1.5%;
      }

      .form-subtitle {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: -1.5%;
      }

      .Dropdown {
        margin: 20px 0 23px;
        border: #8163F6 4px solid;
        height: 78px;
        width: 462px;
        font-weight: 700;
        font-size: 24px;

        .options {
          top: 75px;

          .option {
            padding: 20px;

            &.selected {
              background: #F4FBC8;
            }

            &:hover {
              background: #E8F791;
            }
          }
        }

        &:hover {
          border: #483966 4px solid;
        }

        &.open {
          border: #483966 4px solid;
        }
      }

      .other {
        font-weight: 500;
        margin-top: 16px;

        .tell-us {
          font-weight: 800;
          color: #8163F6;
          cursor: pointer;
        }
      }
    }
    .hero {
      position: absolute;
      left: max(620px, 60%);
    }
    .triangle1 {
      position: absolute;
      right:0;
      height: 580px;
    }

  }

  .bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #4B4B4B;
    background: #F8F1F1;
    font-family: "Red Hat Display", sans-serif;
    padding: 62px 20px 59px;
    gap: 8px;
    overflow: hidden;

    .completed {
      font-weight: 800;
      font-size: 38px;
    }
    .specialize {
      font-size: 24px;
      line-height: 32px;
    }
    .samples {
      display: flex;
      justify-content: center;
      gap: 68px;
      padding: 50px 0;

      .sample {
        display: flex;
        width: 377px;
        height: 150px;
        border: #8163F6 5px solid;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 36px 30px 36px 42px;
        gap: 28px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        align-items: center;
        svg {
          flex-shrink: 0;
        }
        background: white;
      }
    }

    div {
      z-index: 1;
    }
    .triangle3 {
      position: absolute;
      right:0;
      bottom: 0;
    }
    .triangle2 {
      position: absolute;
      left:0;
      top:0;
    }
  }

  &.mobile {
    .top {
      padding: 0 10px 20px;
      text-align: center;
      position: relative;

      .hero {
        position: relative;
        width: 100%;
        left: unset;
      }
      .triangle1 {
        width: 66%;
        height: unset;
        top:0;
      }
      .title {
        font-size: 34px;
        line-height: 42px;
        margin: 17px 10px 0;
      }
      .subtitle {
        font-size: 16px;
        line-height: 24px;
      }
      .form {
        width: 100%;
        padding: 32px 14px 25px;
        margin-bottom: 20px;
        .form-title {
          font-size: 20px;
          line-height: 30px;
        }
        .form-subtitle {
          font-size: 15px;
          line-height: 20px;
        }
        .Dropdown {
          width: 100%;
        }
      }
    }
    .bottom {
      text-align: center;

      .title {
        font-size: 30px;
        line-height: 40px;
        margin: 0 30px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 30px;
        margin: 0 30px;
      }

      .samples {
        flex-direction: column;
        gap: 26px;
      }
    }
  }

}