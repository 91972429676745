.self-footer {
  background: #F8F1F1;
  height: 258px;
  display: flex;
  flex-direction: column;

  .logo-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position-y: 40px;
    background-position-x: center;

    .logo {
      background: #F8F1F1;
      padding: 20px;
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    padding: 5px 82px 74px 81px;
    justify-content: space-between;

    .contact {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .contact-us {
        font-weight: 800;
        color: #8163F6;
        cursor: pointer;
      }

      .tell-us {
        display: flex;
        gap: 0.25em;

        .cta {
          font-weight: 800;
          color: #8163F6;
          cursor: pointer;
        }
      }
    }

    .links {
      display: flex;
      gap: 50px;
      color: #4B4B4B;
      font-family: "Red Hat Display", sans-serif;
      font-weight: 700;
      .privacy {
        text-decoration: none;
      }
      .copyright {
        font-weight: 600;
      }
    }

  }
  &.mobile {
    .bottom {
      flex-direction: column;
      gap: 40px;
      text-align: center;
      .contact {
        align-items: center;
        gap: 26px;
        .tell-us {
          flex-direction: column;
          align-items: center;
        }
      }
      .links {
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }
}